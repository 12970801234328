import { GetStaticProps, InferGetStaticPropsType } from 'next';
import { getHomepageProps } from '@utils/homepage-props';
import { Home as HomePage, Layout } from '@components';

const Home = (props): InferGetStaticPropsType<typeof getStaticProps> => {
	return <HomePage {...props} />;
};

export const getStaticProps: GetStaticProps = async context => {
	const props = await getHomepageProps(context);

	return {
		props: { ...props, page: 'logged-out-homepage' },
	};
};

Home.getLayout = function getLayout(page, pageProps) {
	return <Layout pageProps={pageProps}>{page}</Layout>;
};

export default Home;
